<template>
  <div
    class="flex justify-content-start flex-wrap card-container blue-container"
  >
    <ProgressSpinner />
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import ProgressSpinner from "primevue/progressspinner";

import Oauth2Service from "@/services/oauth2_service";

export default defineComponent({
  components: {
    ProgressSpinner,
  },

  props: ["region"],
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const postOauthCode = async () => {
      let query = route.query;
      let code = query.code;
      console.log(code);
      let payload = {
        auth_code: code,
        region: props.region,
      };
      Oauth2Service.lwa(payload)
        .then(({ result }) => {
          console.log(result);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          router.push({ name: "Amazon/AD/Account" });
        });
    };

    onMounted(async () => {
      await postOauthCode();
    });

    return {};
  },
});
</script>
